import React from "react"
export default function EarthDayPage() {
  return <div></div>
}
// import React, { useState, useEffect, useRef } from "react"
// import styled from "styled-components"
// import Layout from "../components/layout"
// import SEO from "../components/seo"
// // import { useInView } from "react-hook-inview"
// import { useInView } from "react-inview-hook"
// import { motion, AnimatePresence } from "framer-motion"
// import { TiArrowDownOutline } from "react-icons/ti"
// import One from "../components/earth-day/one"

// const EarthDayPage = () => {
//   const [showingStep1, setStep1] = useState(true)
//   const [showingStep2, setStep2] = useState(false)

//   useEffect(() => {
//     if (!showingStep1) {
//       setTimeout(() => {
//         setStep2(true)
//       }, 500)
//     }
//   }, [showingStep1])

//   return (
//     <Layout noNav noBanner>
//       <SEO
//         title="Smile Inn Earth Day Challenge"
//         description="Introducing: The Smile Inn Earth Day Challenge! Earth Day is an annual
//                 event celebrated around the world on April 22. At Smile Inn, we wanted to make this year super interesting!"
//       />
//       <Container changeStart={showingStep2}>
//         <AnimatePresence>
//           {showingStep1 && <One onClick={setStep1} />}
//         </AnimatePresence>
//         <AnimatePresence>
//           {showingStep2 && (
//             <Two positionTransition>
//               <CoverContainer>
//                 <Cover
//                   transition={{ duration: 1.5, delay: 0.5 }}
//                   initial={{ height: "100%" }}
//                   animate={{ height: 0 }}
//                 />
//                 <CoverImage
//                   transition={{ delay: 0.4 }}
//                   initial={{ opacity: 0, scale: 0.8 }}
//                   animate={{ opacity: 1, scale: 1 }}
//                   src={require("../images/reducereuse.png")}
//                 />
//               </CoverContainer>
//               <Copy
//                 initial={{ opacity: 0, scale: 0.99 }}
//                 animate={{ opacity: 1, scale: 1 }}
//                 transition={{ duration: 1.5, delay: 1.7 }}
//               >
//                 <span style={{ color: "green" }}>Earth Day</span> is an annual
//                 event celebrated around the world on April 22 to demonstrate
//                 support for environmental protection.
//               </Copy>
//               <Copy
//                 initial={{ opacity: 0 }}
//                 animate={{ opacity: 1 }}
//                 transition={{ duration: 1, delay: 4 }}
//               >
//                 At Smile Inn, we wanted to make this year super interesting!
//               </Copy>

//               <Arrow
//                 initial={{ opacity: 0, y: 0 }}
//                 animate={{ y: 20, opacity: 1 }}
//                 transition={{
//                   delay: 5,
//                   duration: 2.5,
//                   loop: "Infinity",
//                   flip: Infinity,
//                 }}
//               >
//                 <TiArrowDownOutline size={28} />
//               </Arrow>
//             </Two>
//           )}
//         </AnimatePresence>

//         {showingStep2 && (
//           <Three
//             transition={{ duration: 1, delay: 4 }}
//             initial={{ opacity: 0, y: 30 }}
//             animate={{ opacity: 1, y: 0 }}
//           >
//             <Copy>
//               It's simple!
//               <br />
//               <br />
//               We have a custom drawing with a few hidden gems in between & we
//               want you guys to add some VIBRANCY in celebration of Earth Day!
//             </Copy>

//             <a href="/documents/smile-earth-day.pdf" target="_blank">
//               <DrawingCover src={require("../images/smile-earth-cover.png")} />
//               <Caption>(Tap to Download)</Caption>
//             </a>

//             <List>
//               <ListItem>Download our pdf drawing above</ListItem>
//               <ListItem>Colour, Colour, Colour!</ListItem>
//               <ListItem>
//                 Take a photo and tag us @smileinntt on Instagram OR Facebook
//               </ListItem>
//               <ListItem>Email your finished piece to Smile Inn Dental</ListItem>
//             </List>

//             <BigCopy>
//               Happy Earth Day! <br />
//               Please remember to reduce, reuse and recycle! 🌍
//               <br />
//               <br />
//               -Love, the Smile Team✨
//             </BigCopy>
//           </Three>
//         )}
//       </Container>
//     </Layout>
//   )
// }

// export default EarthDayPage

// const Container = styled.section`
//   min-height: 100vh;
//   background: #bfc4ea;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: ${props => (props.changeStart ? "flex-start" : "center")};
//   text-align: center;

//   padding: 50px;
//   background-color: #bfc4ea;
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 2 1'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='0' x2='0' y1='0' y2='1'%3E%3Cstop offset='0' stop-color='%23bfc4ea'/%3E%3Cstop offset='1' stop-color='%23d0d6ff'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='1'%3E%3Cstop offset='0' stop-color='%23b1b6d9' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23b1b6d9' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='c' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='2' y2='2'%3E%3Cstop offset='0' stop-color='%23b1b6d9' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23b1b6d9' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect x='0' y='0' fill='url(%23a)' width='2' height='1'/%3E%3Cg fill-opacity='0.5'%3E%3Cpolygon fill='url(%23b)' points='0 1 0 0 2 0'/%3E%3Cpolygon fill='url(%23c)' points='2 1 2 0 0 0'/%3E%3C/g%3E%3C/svg%3E");
//   background-attachment: fixed;
//   background-size: cover;
// `
// const Two = styled(motion.div)`
//   max-width: 400px;
//   margin: 0 auto;
//   margin-bottom: 60px;
// `
// const CoverContainer = styled(motion.div)`
//   margin: 0 auto;
//   margin-bottom: 30px;
//   width: 180px;
//   height: 180px;
//   position: relative;
//   overflow: hidden;
// `
// const Cover = styled(motion.div)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   z-index: 3;
//   background: #bfc4ea;
// `
// const CoverImage = styled(motion.img)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// `
// const Copy = styled(motion.p)`
//   font-weight: 500;
//   opacity: 0.85;
//   font-size: 18px;
//   line-height: 1.4;
// `
// const Arrow = styled(motion.div)``

// const Three = styled(motion.div)`
//   max-width: 400px;
//   margin: 0 auto;
// `

// const BigCopy = styled.p`
//   font-weight: 600;
//   opacity: 0.9;
//   line-height: 1.5;
//   font-size: 19px;
// `
// const DrawingCover = styled.img`
//   margin-bottom: 10px;
// `
// const Caption = styled.p`
//   font-size: 12px;
//   color: black;
//   opacity: 0.9;
//   font-style: italic;
// `
// const List = styled.ol`
//   text-align: left;
//   font-family: "Articulat";
//   font-weight: 500;
// `

// const ListItem = styled.li``
